import { AuthenticateModel, AuthenticateResultModel, TokenAuthServiceProxy } from '@/shared/service-proxies';
import { UrlHelper } from '@/shared/helpers/UrlHelper';
import { AppConsts } from '@/abpPro/AppConsts';
import * as _ from 'lodash';
import { rootRouting } from '@/router';
import { apiHttpClient } from '@/shared/utils';
import { abpService } from '@/shared/abp/';

class LoginService {
	authenticateModel: AuthenticateModel = new AuthenticateModel();
	authenticateResult: AuthenticateResultModel;
	rememberMe: boolean;
	_tokenAuthService: TokenAuthServiceProxy;

	constructor() {
		this._tokenAuthService = new TokenAuthServiceProxy(AppConsts.remoteServiceBaseUrl, apiHttpClient);
	}

	authenticate(successCallback?, errorCallback?: () => void, finallyCallback?: () => void, redirectUrl?: string) {
		this._tokenAuthService
			.authenticateToNew(this.authenticateModel)
			.finally(() => {
				finallyCallback();
			})
			.catch((err) => {
				errorCallback();
				return err;
			})
			.then((result) => {
				successCallback(result);				
				this.processAuthenticateResult(result, redirectUrl);
			});
	}
	
	authenticateToLdap(successCallback?, errorCallback?: () => void, finallyCallback?: () => void, redirectUrl?: string) {
		this._tokenAuthService
			.authenticateToLdap(this.authenticateModel)
			.finally(() => {
				finallyCallback();
			})
			.catch((err) => {
				errorCallback();
				return err;
			})
			.then((result) => {
				successCallback(result);
				this.processAuthenticateResult(result, redirectUrl);
			});
	}

	private processAuthenticateResult(authenticateResult: AuthenticateResultModel, redirectUrl?: string) {
		
		if (authenticateResult.waitingForActivation) {
			
			rootRouting.push({
				path: 'account/activation',
				params: {
					state: authenticateResult.toJSON(),
				},
			});
		} else if (authenticateResult.accessToken) {
			
			const ssoGuidExpireDate = authenticateResult.expireInSeconds
				? new Date(new Date().getTime() + 1000 * authenticateResult.expireInSeconds)
				: undefined;
			//写入SSOGuid,设置与token相同的过期时间
			abpService.abp.utils.setCookieValue('SSOGuid', authenticateResult.ssoGuid, ssoGuidExpireDate, abp.appPath);
			this.login(
				authenticateResult.accessToken,
				authenticateResult.encryptedAccessToken,
				authenticateResult.expireInSeconds,
				this.rememberMe
			);
		} else {
			abpService.abp.log.warn('Unexpected authenticateResult!');
		}
	}

	private login(
		accessToken: string,
		encryptedAccessToken: string,
		expireInSeconds: number,
		rememberMe?: boolean,
		twoFactorRememberClientToken?: string,
		redirectUrl?: string
	): void {
		const tokenExpireDate = rememberMe ? new Date(new Date().getTime() + 1000 * expireInSeconds) : undefined;
		
		abpService.abp.auth.setToken(accessToken, tokenExpireDate);

		abpService.abp.utils.setCookieValue(
			AppConsts.authorization.encrptedAuthTokenName,
			encryptedAccessToken,
			tokenExpireDate,
			abp.appPath
		);

		let initialUrl = UrlHelper.initialUrl;
		if (initialUrl.indexOf('/login') > 0) {
			initialUrl = AppConsts.appBaseUrl;
		}
		
		location.href = initialUrl;
	}

	getReturnUrl(): string {
		return UrlHelper.getReturnUrl();
	}
}

const loginService = new LoginService();

export { loginService };
