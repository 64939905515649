<template>
	<a-spin :spinning="loading">
		<div class="main-container">
			<div class="main-left">
				<div class="left-img">
					<img
						:src="tenantName ? companyImagesPath.loginLeft : '/assets/images/login/login_left.png'"
						width="650"
						height="500"
						@error="companyImagesPath.loginLeft = '/assets/images/login/login_left.png'"
						style="margin-right: 20px"
					/>
				</div>
			</div>
			<div class="main-center"></div>
			<div class="main-right">
				<!--                登录使用-->
				<div class="login-model" v-if="viewkey != 5">
					<!--                租户信息-->
					<div class="login-tenant">
						<div class="tenant-change" :title="tenantName ? tenantName : '填写租户'" @click="viewkey = 2">
							<div
								class="tenant-iocn "
								:title="tenantName ? tenantName : '填写租户'"
								v-if="!tenantName"
							></div>
							<img
								:src="companyImagesPath.squareLogo"
								v-else
								width="24"
								height="24"
								style="margin-right: 10px"
							/>
							<div class="tenant-name" :title="tenantName ? tenantName : l('Pleasesetupyourenterprise')">
								{{ tenantName ? tenantName : l('Pleasesetupyourenterprise') }}
							</div>
							<!-- <div class="tenant-name" :title="tenantName ? tenantName : '请设置企业'">
								{{ tenantName ? tenantName : '请设置企业' }}
							</div> -->
						</div>
						<div class="select-tenant" title="选择历史租户" @click="selectTenantView">
							<a-icon
								type="down"
								style="margin: auto;padding-left: 20px"
								v-if="viewkey == 3 ? true : false"
							/>
							<a-icon type="right" style="margin: auto;padding-left: 20px" v-else />
						</div>
					</div>
					<!--                共用view-->
					<div class="login-view">
						<!--                    登录form-->
						<login-form v-if="viewkey == 1" :isShowLDAP="isShowLDAP" @forgetpw="forgetpw" @loginChange="loginChange"></login-form>
						<!--                    租户填写-->
						<tenant-change ref="tenant" v-show="viewkey == 2" @tenantChange="tenantChange"></tenant-change>
						<!--                    选择历史租户-->
						<tenant-list v-if="viewkey == 3" @selectTenant="selectTenant"></tenant-list>
						<!--                    忘记密码-->
						<forget-pwd v-if="viewkey == 4" @forgetPwdSave="forgetPwdSave"></forget-pwd>
					</div>
				</div>
				<!--                重置密码-->
				<div class="login-model" v-else>
					<reset-pass-word :loginInfo="loginInfo" @resetChange="resetChange"></reset-pass-word>
				</div>
			</div>
		</div>
	</a-spin>
</template>

<script>
import AppComponentBase from '../../shared/component-base/app-component-base';
import LoginForm from './components/login-form/login-form';
import TenantChange from './components/tenant-change/tenant-change';
import TenantList from './components/tenant-list/tenant-list';
import ForgetPwd from './components/forget-pwd/forget-pwd';
import { abpService, appSessionService } from '../../shared/abp';
import { OrgWebApiServiceProxy,TenantServiceProxy,SystemConfigServiceProxy } from '../../shared/service-proxies';
import { AppConsts } from '../../abpPro/AppConsts';
import ResetPassWord from './components/reset-password/reset-password';

export default {
	name: 'login',
	mixins: [AppComponentBase],
	components: { LoginForm, TenantChange, TenantList, ForgetPwd, ResetPassWord },
	data() {
		return {
			cookieKey: 'tenantInfoCookie',
			//当前显示的模块key(1、登录表单，2、租户填写 3、历史租户 4、忘记密码 5、密码重置)
			viewkey: 1,
			tenantName: undefined,
			//企业图片相关变量
			companyImagesPath: {
				//企业文化
				loginLeft: '',
				//正方形Logo
				squareLogo: '',
				//长方形Logo
				oblongLogo: '',
			},
			loginInfo: {},
			isShowLDAP: false
		};
	},
	watch: {},
	computed: {
		isTenantId() {
			if (abpService.abp.session.tenantId) {
				return true;
			} else {
				return false;
			}
		},
	},
	created() {
		this._orgWebApiServiceProxy = new OrgWebApiServiceProxy(this.$apiUrl, this.$api);
		this._tenantService = new TenantServiceProxy(this.$apiUrl, this.$api);
		this._systemConfigServiceProxy = new SystemConfigServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		if (appSessionService.tenant) {
			this.tenantName = appSessionService.tenant.name;
		}
		if (abp.multiTenancy.getTenantIdCookie()) {
			await this.getCompanyImgs(abp.multiTenancy.getTenantIdCookie());
			await this.LDAPConfig();
		}
		//默认租户
		let c = abpService.abp.utils.getCookieValue('tenantInfoCookie');
		if (!c) {
			//系统默认租户
			let code= await this.getDefaultTenant();

			if(!code){
				//appconfig配置的默认租户
				// this.$refs.tenant.entity.tenancyName = AppConsts.DefaultTenant;
			}
			else {
				this.$refs.tenant.entity.tenancyName=code;
			}
			this.$refs.tenant.save();
		}
	},
	methods: {
		/**
		 * 获取默认租户(首次登录或加载)
		 */
		getDefaultTenant(){
			return new Promise(resolve => {
				this.loading=true;
				this._tenantService.getDefatultTenantCode()
						.finally(()=>{this.loading=false;})
						.then((code)=>{
							resolve(code);
						});
			});
		},
		/**
		 * 忘记密码回调
		 */
		forgetpw(record) {
			this.viewkey = record.viewkey;
		},
		/**
		 * 租户填写
		 */
		async tenantChange(record) {
			//type(1、正常提交的宿主)
			if (record.data && !record.data.desc && record.type == 1) {
				this.companyImagesPath.loginLeft = undefined;
				this.companyImagesPath.squareLogo = undefined;
				this.tenantName = record.data.desc;
			}
			//返回
			else if (record.type == 0) {
			}
			//type(1、正常提交的租户)
			else {
				await this.getCompanyImgs(record.data.tenantId);
				await this.LDAPConfig();
				this.tenantName = record.data.desc;
				let d = {
					img: this.companyImagesPath.squareLogo,
					name: record.data.desc,
					code: record.code,
					tenantId: record.data.tenantId,
				};
				let c = abpService.abp.utils.getCookieValue(this.cookieKey);
				let cookies = [];
				if (c) {
					cookies = JSON.parse(c);
				}
				this.setTenantInfoCookie(cookies, d);
			}
			this.viewkey = record.viewkey;
		},
		/**
		 * 选择历史租户回调
		 * @param record
		 */
		async selectTenant(record) {
			if (record.data) {
				abpService.abp.multiTenancy.setTenantIdCookie(record.data.tenantId);
				this.tenantName = record.data.name;
				await this.getCompanyImgs(record.data.tenantId);
				await this.LDAPConfig();
			}
			this.viewkey = record.viewkey;
		},
		/**
		 * 忘记密码保存回调
		 */
		forgetPwdSave(record) {
			this.viewkey = record.viewkey;
		},
		/**
		 *点击租户选择事件
		 */
		selectTenantView() {
			if (this.viewkey == 3) {
				this.viewkey = 1;
			} else {
				this.viewkey = 3;
			}
		},
		/**
		 * 获取企业相关图片
		 * @param tenantId
		 */
		getCompanyImgs(tenantId) {
			return new Promise((resolve) => {
				this.loading = true;
				this._orgWebApiServiceProxy
					.getCompanyImagesListByTenantId(tenantId)
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						this.companyImagesPath.loginLeft =
							`${AppConsts.OSSRequestUrl}/${AppConsts.localization.defaultLocalizationSourceName}/LoginLeft/${tenantId}/` +
							res.loginLeft;
						this.companyImagesPath.squareLogo =
							`${AppConsts.OSSRequestUrl}/${AppConsts.localization.defaultLocalizationSourceName}/SquareLogo/${tenantId}/` +
							res.squareLogo;
						this.companyImagesPath.oblongLogo =
							`${AppConsts.OSSRequestUrl}/${AppConsts.localization.defaultLocalizationSourceName}/OblongLogo/${tenantId}/` +
							res.oblongLogo;
						resolve();
					});
			});
		},
		//获取LDAP配置
		LDAPConfig() {
			this._systemConfigServiceProxy.getLdapSetting()
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.isShowLDAP = res.ldapIsActivate;
				});
		},
		/**
		 *写入历史记录cookie
		 * @param cookie
		 */
		setTenantInfoCookie(cookie, data) {
			//过期时间，默认一个月
			var millisecond = new Date().getTime();
			var expiresTime = new Date(millisecond + 60 * 1000 * 60 * 24 * AppConsts.TenantInfoExpiresTime);
			if (cookie) {
				if (cookie.length >= 3) {
					let c = cookie.filter(
						(item, index) => index < 2 && item.name != data.name && item.code != data.code
					);
					c = [...c, data];
					abp.utils.setCookieValue(this.cookieKey, JSON.stringify(c), expiresTime, abp.appPath);
				} else {
					let c = cookie.filter((item, index) => item.name != data.name && item.code != data.code);
					c = [...c, data];
					abp.utils.setCookieValue(this.cookieKey, JSON.stringify(c), expiresTime, abp.appPath);
				}
			}
		},
		/**
		 * 登录回调(重置密码)
		 * @param record
		 */
		loginChange(record) {
			this.loginInfo = record.data;
			this.viewkey = 5;
		},
		/**
		 * 重置密码回调
		 * @param record
		 */
		resetChange(record) {
			this.viewkey = record.viewkey;
		},
	},
};
</script>

<style scoped lang="less">
/deep/ .ant-input {
	height: 50px;
}
.ant-spin-nested-loading {
	width: 100%;
	height: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
@import './login.less';
</style>
