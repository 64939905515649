<template>

    <div class="container">
        <a-spin :spinning="loading">
            <div style="text-align: center">
                <a-input :placeholder="l('CorpCode')" class="from-input"
                         v-model="entity.tenancyName" @pressEnter="save"/>
            </div>
            <div class="from-save">
                <a-button @click="lastStep()">
                    {{l('AppBack')}}
                </a-button>
                <a-button type="primary" @click="save()" :loading="saving">
                    {{l('Confirm')}}
                </a-button>
            </div>
            <div style="width: 80%;margin: 20px auto">
                <p style="width: 100%;height: 1px;background-color: #cccccc"></p>
            </div>
            <div class="tenant-search ">
                <p style="text-align: left">{{l('Findmycompanycode')}}</p>
                <div class="search">
                    <a-input-search
                            :placeholder="l('Pleaseentercompanycode')"
                            :enter-button="l('ASearch')"
                            v-model="filterText"
                            size="large"
                            @search="onSearch"
                    />
                </div>
            </div>
            <div class="search-info" v-if="tenantDto.tenantName&&tenantDto.tenantCode" @click="cilckInfo" :title="tenantDto.tenantName">
                <div>{{tenantDto.tenantName}}</div>
                <div>{{tenantDto.tenantCode}}</div>
            </div>
        </a-spin>
    </div>


</template>

<script>
    import AppComponentBase from "../../../../shared/component-base/app-component-base";
    import {
        AccountsApiServiceProxy,
        AccountServiceProxy,
        GetTenantDto,
        IsTenantAvailableInput
    } from "../../../../shared/service-proxies";
    import {abpService} from "../../../../shared/abp";
    import {AppTenantAvailabilityState} from "../../../../abpPro/AppEnums";
    import {Modal} from "ant-design-vue";

    export default {
        name: "tenant-change",
        mixins: [AppComponentBase],
        data() {
            return {
                entity: new IsTenantAvailableInput(),
                filterText:undefined,
                tenantDto:new GetTenantDto(),
            }
        },
        created() {
            this._accountService = new AccountServiceProxy(this.$apiUrl, this.$api);
            this._accountsApiService = new AccountsApiServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {

        },
        methods: {
            /**
             * 返回上一步
             */
            lastStep() {
                //返回登录表单页面
                this.$emit("tenantChange", {"viewkey": 1,"data":{},"code":"","type":0});
            },
            /**
             * 企业查询
             */
            onSearch(){
                this.loading=true;
                this._accountsApiService.getTenantNameByName(this.filterText)
                    .finally(()=>{this.loading=false;})
                    .then(res=>{
                        this.tenantDto=res;
                    });
            },
            cilckInfo(){
                if(this.tenantDto.tenantCode&&this.tenantDto.tenantName){
                    let t=this.tenantDto.tenantCode;
                    this.entity.tenancyName=t;

                }
                this.tenantDto.tenantCode=undefined;
                this.tenantDto.tenantName=undefined;
                this.filterText=undefined;
            },
            /**
             * 保存
             */
            save() {
                if (!this.entity.tenancyName || this.entity.tenancyName === "") {
                    abpService.abp.multiTenancy.setTenantIdCookie(undefined);
                    //返回登录表单页面
                    this.$emit("tenantChange", {"viewkey": 1,"data":{},"code":"","type":1});
                    return;
                }
                this.loading = true;
                this.saving = true;
                    this._accountService
                        .isTenantAvailable(this.entity)
                        .finally(() => {
                            this.saving = false;
                            this.loading = false;
                        })
                        .then(result => {
                                switch (result.state) {
                                    //成功
                                    case AppTenantAvailabilityState.Available:
                                        abpService.abp.multiTenancy.setTenantIdCookie(result.tenantId);
                                        //返回登录表单页面
                                        this.$emit("tenantChange",{"viewkey":1,"data":result,"code":this.entity.tenancyName,"type":1});
                                        return;
                                    //租户未激活
                                    case AppTenantAvailabilityState.InActive:
                                        Modal.warning({
                                            message: this.l("TenantIsNotActive", input.tenancyName)
                                        });
                                        break;
                                    // 未找到租户信息
                                    case AppTenantAvailabilityState.NotFound: // NotFound
                                        Modal.warning({
                                            content: this.l(
                                                "ThereIsNoTenantDefinedWithName{0}",
                                                input.tenancyName
                                            )
                                        });
                                        break;
                                }
                            },
                            error => {
                                console.error(error);
                            }
                        );
            }
        }
    }
</script>
<style scoped>
    >>> .search .ant-input-wrapper {
        height: 50px;
    }

    >>> .search .ant-input-wrapper input {
        height: 50px;
    }

    >>> .search button {
        height: 50px;
    }
</style>
<style scoped lang="less">
    .container .ant-spin-nested-loading {
        width: 100%;
        height: 100%;

    }

    @import "./tenant-change.less";
</style>
