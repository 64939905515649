<template>
    <div class="container">
        <a-spin :spinning="loading">
            <!--        tab页签-->
            <div class="tabs">
                <div class="tabs-header">
                    <div class="tab" :style="tabkey==1?tabstyle:{}" @click="tabchange(1)">
                        手机验证
                    </div>
                    <div class="tab" :style="tabkey==2?tabstyle:{}" @click="tabchange(2)">
                        邮箱验证
                    </div>
                </div>
                <div class="tab-line">
                    <div class="line" :style="tabkey==1?
                    {'background-color':'#1899FF','transform':'translate3d(0px, 0px, 0px)'}:
                    {'background-color':'#1899FF','transform':'translate3d(165px, 0px, 0px)'}">
                    </div>
                </div>
            </div>
            <div class="view">
                <!--            该DIV目的：遮住隐藏的tab-->
                <div style="overflow: hidden">
                    <div class="view-data" :style="tabkey==1?{'margin-left':'0'}:{'margin-left':'-100%'}">
                        <!--                    手机验证表单-->
                        <div class="tab-view" :style="tabkey==1?{'opacity':'1'}:{'opacity':'0'}">
                            <!--                        手机号-->
                            <div class="form-input">
                                <a-input placeholder="手机号" v-model="phoneEntity.phoneNumbers"/>
                            </div>
                            <!--                        验证码-->
                            <div class="verification">
                                <div class="verification-input">
                                    <a-input placeholder="验证码" v-model="phoneEntity.smsCode"/>
                                </div>
                                <div class="send-verification">
                                    <a-button type="primary" @click="sendVerification" :disabled="isdisabled"
                                              :style="isdisabled?{'font-size':'25px'}:{}">
                                        {{isdisabled?timer+" S":"发送验证码"}}
                                    </a-button>
                                </div>
                            </div>
                            <!--                        密码-->
                            <div class="pwd">
                                <a-input-password placeholder="密码" v-model="phoneEntity.pwd"/>
                            </div>
                            <div class="view-save">
                                <a-button @click="lastStep">
                                    返回
                                </a-button>
                                <a-button type="primary" @click="phoneSave" :loading="saving">
                                    确认
                                </a-button>
                            </div>
                        </div>
                        <!--                    邮箱表单-->
                        <div class="tab-view">
                            <!--                        用户名-->
                            <div class="form-input">
                                <a-input placeholder="用户名" v-model="emailEntity.account"/>
                            </div>
                            <!--                        邮箱-->
                            <div class="form-input">
                                <a-input placeholder="邮箱" v-model="emailEntity.email"/>
                            </div>
                            <div class="view-save">
                                <a-button @click="lastStep">
                                    返回
                                </a-button>
                                <a-button type="primary" @click="emailSave" :loading="saving">
                                    确认
                                </a-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a-spin>
    </div>
</template>


<script>
    import AppComponentBase from "../../../../shared/component-base/app-component-base";
    import {
        AccountsApiServiceProxy,
        UpdatePwdByEmailEditDto,
        UpdatePwdByPhoneEditDto
    } from "../../../../shared/service-proxies";
    import {abpService} from "../../../../shared/abp";


    let t;

    export default {
        name: "forget-pwd",
        mixins: [AppComponentBase],
        data() {
            return {
                tabkey: 1,
                tabstyle: {
                    "font-weight": "bold",
                    "color": "#1899FF"
                },
                timer: 120,
                isdisabled: false,
                phoneEntity: new UpdatePwdByPhoneEditDto(),
                emailEntity:new UpdatePwdByEmailEditDto()
            }
        },
        created() {
            this._accountsApiService = new AccountsApiServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {

        },
        methods: {
            /**
             * 选项卡点击事件
             */
            tabchange(key) {
                this.tabkey = key;
            },
            /**
             * 发送验证码
             */
            sendVerification() {
                if (!this.phoneEntity.phoneNumbers) {
                    return abp.message.warn("请输入手机号");
                }
                this._accountsApiService.sendVerificationCode(this.phoneEntity.phoneNumbers)
                    .finally(() => {
                    })
                    .then(res => {
                        if (res.success) {
                            this.$notification["success"]({
                                message: this.l("验证码发送成功"),
                            });
                            this.timer = 120;
                            this.isdisabled = true;
                            t = setInterval(() => {
                                this.timer -= 1;
                                if (this.timer <= 0) {
                                    //点击返回按钮时记得也要关闭定时器
                                    window.clearInterval(t);
                                    this.isdisabled = false;
                                }
                            }, 1000);
                        } else {
                            return abp.message.error(res.error);
                        }
                    })
            },
            /**
             * 手机验证提交事件
             */
            phoneSave() {
                if(!this.phoneEntity.phoneNumbers){
                    return abp.message.warn("请输入手机号")
                }
                if(!this.phoneEntity.smsCode){
                    return abp.message.warn("请输入验证码")
                }
                if(!this.phoneEntity.pwd){
                    return abp.message.warn("请输入密码")
                }
                if(this.phoneEntity.pwd.length<6){
                    return abp.message.warn("密码长度不能小于六位")
                }
                this.phoneEntity.tenantId=abpService.abp.multiTenancy.getTenantIdCookie();
                this.loading=true;
                this.saving=true;
                this._accountsApiService.retrievePwdByPhone(this.phoneEntity)
                .finally(()=>{
                    this.loading=false;
                    this.saving=false;
                })
                .then(res=>{
                    window.clearInterval(t);
                    this.$notification["success"]({
                        message: this.l("密码修改成功"),
                    });
                    this.lastStep();
                })

            },
            /**
             * 邮箱验证提交事件
             */
            emailSave() {
                if(!this.emailEntity.email){
                    return abp.message.warn("请输入用户名")
                }
                if(!this.emailEntity.email){
                    return abp.message.warn("请输入账号绑定的邮箱")
                }
                this.emailEntity.tenantId=abpService.abp.multiTenancy.getTenantIdCookie();
                this.loading=true;
                this.saving=true;
                this._accountsApiService.sendEmailPasswordResetLink(this.emailEntity)
                .finally(()=>{
                    this.loading=false;
                    this.saving=false;
                })
                .then(res=>{
                    if(res.success){
                        this.$notification["success"]({
                            message: this.l("密码重置邮件发送成功"),
                        });
                    }
                    else {
                        return abp.message.error(res.errorMes)
                    }
                })

            },
            /**
             * 返回上一步
             */
            lastStep() {
                window.clearInterval(t);
                //返回登录表单页面
                this.$emit("forgetPwdSave", {"viewkey": 1})
            },
        }
    }
</script>

<style scoped lang="less">
    @import "./forget-pwd.less";
</style>
