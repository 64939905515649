<template>
    <div class="reset-view">
        <a-spin :spinning="loading">
            <div class="reset-title" v-if="loginInfo.passwordResetType==2">{{l('Theresetcycletimehasbeenreachedpleaseresetthepasswordthelastresettime')}}<b>{{loginInfo.lastUpdatePassDate}}</b></div>
            <div class="reset-title" v-if="loginInfo.passwordResetType==1">{{l('Pleasesetanewpasswordforthefirstlogin')}}</div>
            <div class="reset-title" v-if="loginInfo.passwordResetType==3">{{l('Thepassworddoesnotmeetthesecurityrulespleasechangeyourpassword')}}</div>
            <div class="reset-form">
                <div class="from-pwd">
                    <a-input-password :placeholder="l('AppCurrentPwd')" ref="oldpwd" @pressEnter="$refs.newpwd.focus()" v-model="entity.currentPassword" />
                </div>
                <div class="from-pwd">
                    <a-input-password :placeholder="l('AppNewPwd')" ref="newpwd" @pressEnter="$refs.pwd.focus()" v-model="newpwd" />
                </div>
                <div class="from-pwd">
                    <a-input-password :placeholder="l('AppNewPwdAgain')" ref="pwd" @pressEnter="save" v-model="entity.newPassword"/>
                </div>
                <div class="from-save">
                    <a-button   @click="lastStep">
                        {{l('Back')}}
                    </a-button>
                    <a-button type="primary" :loading="saving" @click="save">
                        {{l('Save')}}
                    </a-button>
                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
    import AppComponentBase from "../../../../shared/component-base/app-component-base";
    import {ChangePasswordByTenantInput, ProfileServiceProxy} from "../../../../shared/service-proxies";
    import {abpService} from "../../../../shared/abp";
    import moment from 'moment'

    export default {
        name: "reset-password",
        mixins:[AppComponentBase],
        props:{
            loginInfo:{
                type:Object,
                default:{}
            }
        },
        data(){
            return{
                entity:new ChangePasswordByTenantInput(),
                newpwd:undefined
            }
        },
        created() {
            if(this.loginInfo.lastUpdatePassDate){
                this.loginInfo.lastUpdatePassDate=moment(this.loginInfo.lastUpdatePassDate).format("YYYY-MM-DD")
            }
            this._profileService = new ProfileServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.$refs.oldpwd.focus();
            console.log(abp.multiTenancy.getTenantIdCookie())
        },
        methods:{
            save(){
                if(!this.entity.currentPassword){
                    return abp.message.warn("原密码不能为空");
                }
                if(!this.newpwd){
                    return abp.message.warn("新密码不能为空");
                }
                if(!this.entity.newPassword){
                    return abp.message.warn("确认密码不能为空");
                }
                if(this.newpwd!=this.entity.newPassword){
                    return  abp.message.warn("两次密码输入不一致");
                }
                this.entity.userId=this.loginInfo.userId;
                this.entity.tenantId=abpService.abp.multiTenancy.getTenantIdCookie();
                this.loading=true;
                this.saving=true;
                this._profileService.changePasswordByTenant(this.entity)
                .finally(()=>{
                    this.loading=false;
                    this.saving=false;
                }).then(res=>{
                    this.$notification["success"]({
                        message: this.l("密码修改成功"),
                    });
                    this.lastStep();
                });
            },
            /**
             * 返回上一步
             */
            lastStep() {
                //返回登录表单页面
                this.$emit("resetChange", {"viewkey": 1})
            },
        },

    }
</script>
<style scoped>

</style>
<style scoped lang="less">

@import "./reset-password.less";
</style>
