<template>
	<div class="main-container">
		<div class="main-part">
			<div class="main-img">
				<img
					class="img"
					v-if="companyData.loginLeft"
					:src="defaultLeft ? defaultLeft : companyData.loginLeft"
					@error="defaultLeft = '/assets/images/login/login_left.png'"
				/>
				<img class="img" v-else src="/assets/images/login/login_left.png" />
			</div>
			<div style="position: relative;box-shadow: 0 0 5px 5px rgba(0,0,0,.1)">
				<a-spin :spinning="isShowSpin">
					<div class="card-wrap">
						<div class="tenant-wrap">
							<tenant-change
								@showSpin="showSpin"
								:namePic="squareLogo"
								ref="tenantChange"
								:is-password="1"
							></tenant-change>
						</div>
						<div class="forget-mm-wrap">
							<div class="send-msg">
								<!--                               <div>-->
								<!--                                   邮箱验证已通过，请设置密码-->
								<!--                               </div>-->
								<a-input class="input" placeholder="新密码" v-model="newPwd1" type="password"></a-input>
							</div>
							<a-input
								class="input"
								placeholder="请再次输入密码"
								type="password"
								v-model="newPwd2"
								@pressEnter="resetPassword"
							></a-input>
							<a-button class="button" type="primary" @click="resetPassword" :loading="isLoading">
								确认重置
							</a-button>
						</div>
					</div>
				</a-spin>
			</div>
		</div>
	</div>
</template>

<script type="javascript">
import {
    TokenAuthServiceProxy,
    AccountsApiServiceProxy,
    AccountServiceProxy,
    OrgWebApiServiceProxy
} from "@/shared/service-proxies";
import {AppComponentBase} from "@/shared/component-base";
import AFormItem from "ant-design-vue/es/form/FormItem";
import {abpService, appSessionService} from '@/shared/abp';
import {AppConsts} from "@/abpPro/AppConsts";

import {IsTenantAvailableInput, UpdatePwdByEmailParam, UpdatePwdByPhoneEditDto} from "../../shared/service-proxies";
import {UrlHelper} from "../../shared/helpers";


export default {
    name: "setPassword",
    components: {
        AFormItem,
    },
    mixins: [AppComponentBase],
    data() {
        return {
            tmpPrimaryKey: undefined,
            verificationImgUrl: undefined, // 验证码地址
            form: this.$form.createForm(this),
            tokenAuthService: TokenAuthServiceProxy,
            accountsApiService: AccountsApiServiceProxy,
            accountService: undefined,
            isShowSpin: false,
            orgWebApiService: undefined,
            companyData: '',
            companyImagesPath: '',
            squareLogo: '',
            newPwd1:"",
            newPwd2:"",
            defaultLeft: '',
            isLoading:false,//提交中
            //url参数
            Key:undefined,
            code:undefined
        };
    },
    computed: {
        showTenantChange() {
            return abpService.abp.multiTenancy.isEnabled;
        },
    },
    created() {
        this.tokenAuthService = new TokenAuthServiceProxy(this.$apiUrl, this.$api);
        this.accountsApiService = new AccountsApiServiceProxy(this.$apiUrl, this.$api);
        this.accountService = new AccountServiceProxy(this.$apiUrl, this.$api);
        this.orgWebApiService = new OrgWebApiServiceProxy(this.$apiUrl, this.$api)

    },
    mounted() {

        //验证的Guid
        this.Key=UrlHelper.getQueryVariable("Key");
        if(this.Key){
            this.initData();
        }


    },
    watch: {},
    methods: {
        initData(){
            this.accountsApiService.getLinkIsActivate(this.Key).then(res=>{
                if(res.success){
                    if (appSessionService.tenant) {
                        let data = {};
                        data.bool = false;
                        data.tenantId = appSessionService.tenant.id;
                        data.name = appSessionService.tenant.name;
                        this.companyImagesPath = {
                            loginLeft: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/LoginLeft/${abp.session.tenantId}/`,
                            squareLogo: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/SquareLogo/${abp.session.tenantId}/`,
                            oblongLogo: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/OblongLogo/${abp.session.tenantId}/`,
                            webBackground: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/WebBackground/${abp.session.tenantId}/`,
                            appBackground: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/AppBackground/${abp.session.tenantId}/`
                        }
                        this.showSpin(data);
                    }
                }else {
                    this.$confirm({
                        content:res.errorMes,
                        okText:"确认",
                        cancelText:"取消",
                        title:"密码重置",
                        onOk:()=>{

                            this.$router.push("/login/login")
                        },
                        onCancel:()=>{

                            this.$router.push("/login/login")
                        }
                    })
                }
            })

        },
        showSpin(data) {
            // data是个对象或者是false，如果是点了取消就是 false
            if (!!data) {
                this.isShowSpin = data.bool
                if (data.name) {//之前是name现在是图片了，有name表示选输入了租户名称，没有表示没有租户名称
                    this.orgWebApiService.getCompanyImagesListByTenantId(data.tenantId).then(result => {
                        this.companyImagesPath = {
                            loginLeft: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/LoginLeft/${data.tenantId}/`,
                            squareLogo: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/SquareLogo/${data.tenantId}/`,
                            oblongLogo: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/OblongLogo/${data.tenantId}/`,
                            webBackground: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/WebBackground/${data.tenantId}/`,
                            appBackground: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/AppBackground/${data.tenantId}/`
                        }
                        if (result.loginLeft) {
                            result.loginLeft = this.companyImagesPath.loginLeft + result.loginLeft

                        }
                        if (result.webBackground) {
                            result.webBackground = this.companyImagesPath.webBackground + result.webBackground

                        }
                        if (result.squareLogo) {
                            this.squareLogo = result.squareLogo = this.companyImagesPath.squareLogo + result.squareLogo

                        }

                        this.companyData = result
                        this.$emit('changeLoginData', result)
                    })
                } else {
                    let result = {}
                    result.loginLeft = ''
                    this.squareLogo = ''
                    this.companyData = result
                    this.$emit('changeLoginData', result)

                }
            } else {
                this.isShowSpin = data

            }

        },
        resetPassword(){
            if(!this.newPwd1 || !this.newPwd2 || this.newPwd1.length < 6){
                abp.message.warn('请输入至少6位数的密码')
                return;
            }else if(this.newPwd1 !== this.newPwd2){
                abp.message.warn("两次输入不一致")
                return;
            }

            let entity = new UpdatePwdByEmailParam();
            entity.key = this.Key;
            entity.pwd = this.newPwd1;
            this.isLoading = true;
            this.accountsApiService.retrievePwdByEmail(entity).then(res=>{
                if(res.success){
                    this.$notification["success"]({
                        message: this.l("密码重置成功")
                    });
                    setTimeout(()=>{
                        location.href = AppConsts.appBaseUrl;
                    },1000)
                }else {
                    abp.message.info(res.errorMes)
                }
                this.isLoading = false
            })
        },

    }
};
</script>

<style lang="less" scoped>
@import './setPassword';
</style>
