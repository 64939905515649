<template>
    <div class="container">
        <div class="data-source" v-for="(item,index) in datasource" :key="index"  v-if="datasource.length>0">
            <div class="data-left" :title="item.name" @click="selectTenant(item)">
                <div class="tenant-img">
                    <img :src="item.img" width="40" height="40">
                </div>
                <div class="tenant-info">
                    <div class="tenant-name">
                        {{item.name}}
                    </div>
                    <div class="tenant-code">
                       {{item.code}}
                    </div>
                </div>
            </div>
            <div class="data-right">
                <a style="margin: auto" @click="deleteCookie(item)">删除</a>
            </div>
        </div>
        <div class="no-data" v-if="datasource.length<=0">
            无历史租户
        </div>
    </div>
</template>

<script>
    import AppComponentBase from "../../../../shared/component-base/app-component-base";
    import {abpService} from "../../../../shared/abp";
    import {AppConsts} from "../../../../abpPro/AppConsts";

    export default {
        name: "tenant-list",
        mixins: [AppComponentBase],
        data(){
            return{
                cookieKey:"tenantInfoCookie",
                datasource:[]
            }
        },
        created() {

        },
        mounted() {
            let c=abpService.abp.utils.getCookieValue(this.cookieKey);
            if(c){
                this.datasource=JSON.parse(c);
            }
        },
        methods:{
            /**
             * 租户选择
             * @param data
             */
            selectTenant(data){
                this.$emit("selectTenant",{"data":data,"viewkey":1})
            },
            /**
             * 删除历史记录
             * @param record
             */
            deleteCookie(record){
                this.datasource=this.datasource.filter(item=>item.code!=record.code&&item.name!=record.name);
                let c=abpService.abp.utils.getCookieValue(this.cookieKey);
                let cookies=JSON.parse(c);
                cookies=cookies.filter(item=>item.name!=record.name&&item.code!=record.code);
                if(cookies.length<=0){
                    cookies=undefined;
                }
                //过期时间一个月
                var millisecond = new Date().getTime();
                var expiresTime = new Date(millisecond + 60 * 1000 * 60 * 24 * AppConsts.TenantInfoExpiresTime);
                abpService.abp.utils.setCookieValue(this.cookieKey,JSON.stringify(cookies),expiresTime,abp.appPath);
            }
        }
    }
</script>

<style scoped lang="less">
@import "./tenant-list.less";
</style>
